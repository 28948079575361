@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

.popin {
    font-family: 'Poppins', sans-serif;

}

.login-logo {
    margin: 40px 0 90px 40px;
}

.h-minus_top {
    height: calc(100% - 360px);
}

.custom_login h4 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #E9F2F7;
    margin: 0 0 30px 0 !important;
}

.custom_login .modal-dialog {
    box-shadow: none;
    border-radius: 0px;
}

.custom_login .modal-content {
    background: none;
    border: none;
}

.custom_login .modal-footer {
    background: none;
    border: none;
}

.creat_account {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #E9F2F7;
}

.custom_login input {
    height: 56px;
    background: rgba(247, 248, 248, 0.1);
    border: 1px solid rgba(247, 248, 248, 0.2);
    border-radius: 4px;
    padding-left: 52px;
    color: #A1A8B9;
}

.custom_login input:focus {
    background: rgba(247, 248, 248, 0.1);
    border: 1px solid rgba(247, 248, 248, 0.2);
    color: #A1A8B9;
}

.login_btn {
    height: 48px;
    background: #6859F5;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #E9F2F7;
    width: 100%;
    margin: 0 0 25px 0;
}

.custom_login .modal-body {
    padding: 0px;
}

.custom_login .modal-dialog {
    max-width: 376px;
}

.email_bg {
    position: absolute;
    top: 21px;
    left: 18px;
}

.password_bg {
    position: absolute;
    top: 16px;
    left: 18px;
}

.custom_login .mb-3 {
    position: relative;
    margin: 0 0 15px 0 !important;
}

.forgot_pass_col {
    margin: 0 0 34px 0;
}

.forgot_pass_col img {
    float: left;
    margin: 0 10px 0 0;
}

.forgot_pass_col button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #E9F2F7;
    background: none;
    border: none;
}

.password_eye_bg {
    cursor: pointer;
    position: absolute;
    right: 17px;
    top: 16px;
}

.modal-dialog.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70px);
    box-shadow: none;
    max-width: 456px;
    margin-top: 42px;
}

.custom-modal .modal-content {
    padding: 40px;
    margin-top: 0;
    margin-bottom: 0;
    background: #ffffff;
    border: 1px solid rgba(247, 248, 248, 0.1);
    box-shadow: 0px 12px 24px rgba(255, 255, 255, 0.12);
    border-radius: 12px;
}

.custom-modal .modal-header {
    background: none;
    padding: 0px;
    text-align: center;
    border: none;
}

.custom-modal .modal-header h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #121212;
}

.custom-modal .modal-header p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #121212;
}

.custom-modal .modal-footer {
    padding: 0px;
    margin-top: 25px;
    background: none;
}

.custom-modal .login_btn {
    border: none;
    margin: 0px;
}

.custom-modal .modal-header .btn-close {
    color: #121212;
    position: absolute;
    right: 21px;
    top: 9px;
    background: none;
    opacity: 1;
    opacity: initial;
    padding: 0;
    margin: 0;
    line-height: normal;
    font-size: 30px;
    width: auto;
    height: auto;
}

.custom-modal .modal-header .btn-close:hover {
    opacity: 0.5;
}

.fixed-header .app-main {}

.logo-srcc {
    z-index: 101;
    cursor: pointer;
    width: 92px;
}

.logo-srcc img {
    width: 100%;
}

.dashboard_pages_nav {
    float: left;
    margin: 0px 0 0px 0;
    padding: 30px 0 0 0;
    width: 100%;
}

.dashboard_pages_nav ul {
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-bottom: solid 1px #C3BDFB;
}

.dashboard_pages_nav ul li {
    float: left;
    margin: 0px 0 5px 0;
    padding: 7px 11px;
    width: 100%;
    list-style-type: none;
    border-radius: 12px;
}

.dashboard_pages_nav ul li a {
    float: left;
    margin: 0px;
    padding: 10px 5px 10px 5px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #78788E;
    border-radius: 12px;
}

.dashboard_pages_nav ul li a span{
    float: left;
    margin: 3px 0 0 0;
}

.dashboard_pages_nav ul li svg {
    float: left;
    margin: 0 11px 0 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    fill: #78788E;
}

.dashboard_pages_nav ul li:hover svg {
    fill: #ffffff;
}

.dashboard_pages_nav ul li:hover {
    background: #6859F5;
}

.dashboard_pages_nav ul li.active {
    background: #6859F5;
    
}

.dashboard_pages_nav ul li.active svg {
    fill: #ffffff;
}

.dashboard_pages_nav ul li.active  a{
    color: #fff;
}

.dashboard_pages_nav ul li:hover  a{
    color: #fff;
}


.dashboard_pages_nav ul:last-child{
    border-bottom: none;
}

.sidebar_bot_title {
    float: left;
    margin: 22px 0 10px 0;
    padding: 0px 26px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #78788E;
}





.bots_side_nav {
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-height: calc(100vh - 340px);
    overflow: scroll;
}

.bots_side_nav ul {
    float: left;
    margin: 0px;
    padding: 0 0 14px 0px;
    width: 100%;
    border-bottom: solid 1px #C3BDFB !important;
}

.bots_side_nav ul li {
    float: left;
    margin: 0px 0 0px 0px;
    padding: 6px 8px 0px 8px;
    width: 100%;
    list-style-type: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #E9F2F7;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

.bots_side_nav ul li a {
    display: inline-block;
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
}

.bots_side_nav ul li:hover {
    background:none;
}

.bots_side_nav ul li.active {
    background: none;
}

.bots_side_nav ul li img {
    float: left;
    width: 24px;
    margin: 0 8px 0 0px;
}

.bots_side_nav ul li span.bot_name {
    margin: 2px 0 0 0;
    float: left;
    color: #121212;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 104px;
    overflow: hidden;
}

.bot_status_circle {
    width: 8px;
    height: 8px;
    background: #0098E9;
    float: right;
    border-radius: 50px;
    margin: 8px 4px 0 0px !important;
}

.c_primary {
    background: #0098E9;
}

.c_red {
    background: #FF476C;
    ;
}

.c_yellow {
    background: #FFE999;
}

.app-sidebar.sidebar-shadow {
    box-shadow: none;
    background: #F2F6FC !important;
    border-right: 1px solid #C3BDFB !important;
    top: 98px;
}


.fixed-sidebar.closed-sidebar .logo-srcc {
    display: none;
}

.fixed-sidebar.closed-sidebar .page_nav_link {
    display: none;
}

.fixed-sidebar.closed-sidebar .bot_name {
    display: none;
}

.fixed-sidebar.closed-sidebar .bot_status_circle {
    position: absolute;
    top: 0;
    right: 0;
}


.fixed-sidebar.closed-sidebar .dashboard_pages_nav ul li svg {
    width: 24px;
    height: 24px;
}

.fixed-sidebar.closed-sidebar .bots_side_nav ul li img {
    width: 30px;
}


.home_dashboard_main h2 {
    font-family: 'Poppins', sans-serif;
    color: var(--black, #121212);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 8px 0 25px 0;
    text-align: left;
    padding: 0px;
    float: left;
}

.shadow_none {
    box-shadow: none;
}

.bg_none {
    background: none;
}

.m_l_0 {
    margin-left: 0px;
}

.m_r_0 {
    margin-right: 0px;
}

.p_l_0 {
    padding-left: 0px;
}

.p_r_0 {
    padding-right: 0px;
}

.border_none{
    border: none;
}

.home_dashboard_main .btn_custom_tabs:first-child {
    margin-left: 0px !important;
}

.btn_custom_tabs {
    background: #fff;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #78788E;
    padding: 4px 8px;
    border-color: #EEF0F4;
    margin: 0 4px;
}

.btn_custom_tabs:hover {
    border-color: #6859F5;
    background-color: #fff;
    color: #121212 !important;
}

.btn_custom_tabs.active {
    border-color: #6859F5;
    background-color: #fff;
    color: #121212 !important;
}


.dashboard_top_heading {
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.add_bot_btn {
    float: right;
    background: #0098E9;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #E9F2F7;
    padding: 10px 12px;
    border: none;
    outline: none;
    margin: 2px 27px 0 0px;
}
.add_download_convo_bot {
    float: left;
    background: #0098E9;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #E9F2F7;
    padding: 10px 12px;
    border: none;
    outline: none;
    margin: 2px 27px 0 0px;
}

.add_bot_btn i {
    margin: 0 0 0 12px;
}


.metrics_combine {
    float: left;
    width: 100%;
    text-align: left;
}

.icon_wrap_custom {
    float: right;
    width: auto;
}


.card_custom_home {
    background: #fff;
    border-radius: 16px;
    padding: 28px 28px 26px 28px;
    margin: 0 8px 32px 0;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);


}


.card_custom_home h4 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #78788E;
    margin: 0 0 14px 0;
}

.card_custom_home .widget-numbers {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #121212;
}

.card_custom_home .widget-numbers h5 {
    float: left;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #121212;
    margin: 5px 0 0 0;
}

.card_custom_home .widget-numbers span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #9fb2bd;
    text-transform: uppercase;
    margin-left: 8px;
}

.user_graph_up{
    color: #2BB150 !important;
}

.icon_wrap_custom {
    float: right;
    width: auto;
    position: absolute;
    right: 28px;
    top: 28px;
}

.icon_wrap_custom svg{
    width: 22px;
    height: 22px;
    fill: #78788e;
}

.card_bg_1 {
    background: rgba(43, 152, 212, 0.1);
}


.card_bg_2 {
    background: #22272E;
}


.card_bg_3 {
    background: rgba(140, 77, 204, 0.1);
}


.card_bg_4 {
    background: #22272E;
}

.card_bg_5 {
    background: rgba(43, 152, 212, 0.1);
}

.card_bg_6 {
    background: #33232E;
}

.color_red {
    color: #FF476C !important;
}

.arrow_down_move i {
    -webkit-transform: rotate(50deg) !important;
    transform: rotate(50deg) !important;
}


.card_custom_home .widget-numbers span i {
    transform: rotate(-50deg);
}

.metricsline_bar span {
    background: #6859F5;
    border-radius: 4px;
    height: 8px;
    width: 100%;
    margin: 0 0 8px 0 !important;
    float: left;
}

.metricsline_bar span.half_opacity {
    width: 80%;
    background: rgba(104, 89, 245, 0.19);
}

.metricsline_bar {
    float: left;
    margin: 8px 0 0 0;
    padding: 0px;
    width: 100%;
}

.hours_chart {
    width: 100%;
}

.hours_chart img {
    width: 100%;
}


.download_btn {
    float: right;
    background: #fff;
    border: 1px solid #6859F5;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    padding: 10px 12px;
    margin: 0px 0px 30px 0px;
    color: var(--main-blue, #6859F5);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.question_text {
    background: #F2F6FC;
    border-radius: 8px;
    padding: 14px 12px;
    margin: 0 0 8px 0;
    cursor: pointer;
    border: solid 1px #D7E2EB;
}

.question_text p {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0px;
}

.question_text p span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #A1A8B9;
    margin: 0 12px 0 0;
}

.download_btn img {
    margin: -2px 0 0 12px;
}


.dashboard_questons {
    margin-top: 12px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    padding: 36px;
}


.dashboard_top_heading {
    float: left;
    margin: 0px;
    padding: 0 25px 0 17px;
    width: 100%;
}

.dashboard_top_heading h3 {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0 0 18px 0px;
}

.dashboard_top_heading h3 span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #A1A8B9;
}

.dashboard_top_heading h2 span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #121212;
}

.dashboard_top_heading h2 img {
    margin: 0 8px 0 0px;
    max-width: 36px;
    border-radius: 8px;
}

.accordian_Section_bot {
    float: left;
    width: 100%;
}

.accordian_Section_bot .accordion-item:first-of-type {
    border-radius: 0px;
}

.accordian_Section_bot .accordion-item {
    background: none;
    background: #fff;
    border: none;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    border-radius: 16px !important;
    padding: 24px;
    margin: 0 0 15px 0;
}

.accordian_Section_bot .accordion-button {
    padding: 0;
    background: none;
    box-shadow: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #121212;
}

.accordian_Section_bot .accordion-button:not(.collapsed)::after {
    background-image: url(../assets/utils/images/chevron.svg);
    width: 12px;
    height: 8px;
    background-size: 100%;
}

.accordian_Section_bot .accordion-button::after {
    background-image: url(../assets/utils/images/chevron.svg);
    width: 12px;
    height: 8px;
    background-size: 100%;
}

.accordian_Section_bot .accordion-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 32px;
    padding-bottom: 0px;
}

.bot_form_info p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A1A8B9;
    margin: 8px 0 36px 0px;
}

.bot_form_info .mb-3 {
    max-width: 75%;
    position: relative;
    margin: 0 0 20px 0 !important;
}

.bot_form_info input {
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #474747;
}

.bot_form_info input:focus {
    background:#fff;
    border: 1px solid #6859F5;
    outline: none;
    box-shadow: none;
    color: #474747;
}

.bot_form_info label {
    position: relative;
    margin: 0px 0 8px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
}

.bot_form_info input:focus label {
    opacity: unset;
}

.bot_create_knowledgebase p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A1A8B9;
    margin: 0 0 22px 0px;
}

.bot_create_knowledgebase h6 {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 10px 0px;
}

.bot-creation2{
    width: 50%;
    float: left;
}
.bot-creation{
    /* display: flex; */
}

.header__pane button.Burger{
    display: none;
}


.file_uploa_client.file_upload_accordian {
    float: left;
    text-align: left;
}

.file_upload_accordian .f_u_container input {
    border: 1px dashed #C9D1D7;
    border-radius: 8px;
    background: none;
}

.file_upload_accordian .f_u_container span{
    float: left;
    margin: 0px;
}

.file_upload_accordian .f_u_container span img {
    position: absolute;
    top: 19px;
    left: 18px;
}


.file_upload_accordian .f_u_container h6{
    float: left;
    color: var(--black, #121212);
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 18px 0 0 12px;
}





































@media only screen and (max-width: 760px) {
    .create_ai_bot_main{
        width: 100%;
    }
    .app-main__inner{
        flex-direction: column;
    }
  }

@media only screen and (max-width: 768px) {
  
  .header__pane button.Burger{
        display: block;
    }

}




































.file-upload-container {
    text-align: center;
    margin: 0 0 12px 0;
}

.file-upload {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 7px 16px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #6859F5;
    border-radius: 12px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #6859F5;
}

.file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 18px;
    font-weight: bold;
    color: #555555;
}

.upload-icon {
    font-size: 24px;
    margin: 0 0 0 10px;
}

.upload-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #6859F5;
}

.file-upload input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.file-upload-container input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.error_file_label {
    position: absolute;
    z-index: 10;
    left: 0;
    text-align: left;
    bottom: -27px;
}

.file_upload_error {
    border-color: #d92550;
    overflow: unset;
}

.file-list {
    margin-top: 20px;
}

.file-list h3 {
    font-size: 18px;
    color: #555555;
}

.file-list ul {
    list-style-type: none;
    padding: 0;
    background: rgba(247, 248, 248, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.file-list li {
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(247, 248, 248, 0.1);
    text-align: left;
    padding: 10px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #E9F2F7;
}

.file-icon {
    margin-right: 10px;
    font-size: 24px;
}

.file-name {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
}

.delete-button {
    padding: 0;
    color: white;
    border: none;
    border-radius: 0;
    cursor: pointer;
    background: no-repeat;
    float: right;
    margin: 4px 0 0 0;
}

.bot_setup_section p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A1A8B9;
    margin: 0 0 22px 0px;
}


.bot_setup_section .mb-3 {
    max-width: 100%;
    position: relative;
    margin: 0 0 0px 0 !important;
    padding-bottom: 7px;
    /* border-bottom: 1px solid rgba(247, 248, 248, 0.1); */
}

.bot_setup_section input {
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #78788E;
}

.bot_setup_section input:focus {
    background:#fff;
    border: 1px solid #6859F5 !important;
    outline: none;
    box-shadow: none;
    color: #474747 !important;
}

.bot_setup_section label {
    position: relative;
    margin: 0px 0 8px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
}

@media only screen and (max-width: 500px) {
    .bot_setup_section input {
        padding-left: 15px;
        width: 100px;
    }
  }

.bot_setup_section input:focus {
    background: rgba(247, 248, 248, 0.1);
    border: 1px solid #E9F2F7;
    outline: none;
    box-shadow: none;
    color: #fff;
}

.bot_setup_section .togglebutton {
    margin: 0 0 16px 0;
    position: relative;
}

.togglebutton span {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    position: absolute;
    z-index: 1;
    left: 53px;
    top: 4px;
    opacity: unset !important;
}

.bot_setup_section button {
    background: #6859F5;
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #E9F2F7;
    padding: 12px;
    width: 100%;
    border: none;
    outline: none;
    margin: 22px 0 0 0;
}


.sidenav_user_logedin {
    position: fixed;
    float: left;
    margin: 0px 0 10px 0px;
    padding: 6px 8px;
    width: 100%;
    list-style-type: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #E9F2F7;
    border-radius: 4px;
    cursor: pointer;
    bottom: 0;
    width: auto;
    left: 12px;
}

.sidenav_user_logedin_col {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
}

.sidenav_user_logedin_col:last-child {
    margin-bottom: 0px;
}


.sidenav_user_logedin img {
    float: left;
    width: 24px;
    margin: 0 8px 0 0px;
}

.sidenav_user_logedin span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #78788E;
}

.bot_status_check {
    float: left;
    margin: -7px 0 20px 0;
    padding: 0 15px;
    width: 100%;
}

.bot_status_check a {
    background: rgb(251 197 0);
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #ffffff;
    padding: 4px 8px;
    margin: 0 16px 0 0;
    float: left;
}

.bot_status_working a{
    background: #2B98D433;
    color: #0098E9;
}


.bot_status_check a span {
    background: #ffffff;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    margin: 5.5px 5px 0 0px;
    float: left;
}

.bot_status_working a span{
    background: #0098E9;
}

.bot_status_check p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #121212;
    float: left;
    margin: 4px 0 1px 0;
}

.bot_status_check p span {
    margin: 0 0 0 8px;
}

.bot_status_check p span img {
    margin: 0 4px 0 0px;
    width: 16px;
}


.onoff-switch {
    width: 42px;
    height: 28px;
    border: none;
    border-radius: 20px;
    overflow: hidden;
    transition: background-color 0.3s ease;
}

.onoff-switch .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: left 0.3s ease;
}

.onoff-switch .btn:first-child {
    background-color: #fff;
    left: 0;
}

.onoff-switch .btn:last-child {
    background-color: #fbc1c1;
    left: 40px;
    margin: 0;
}

.onoff-switch.on {
    background-color: #6859F5;
}

.onoff-switch.on .btn:first-child {
    left: 15px;
    margin: 2px 0 0 0;
    text-indent: -9999px;
}

.onoff-switch.on .btn:last-child {
    left: 0;
}

.onoff-switch.off {
    background-color: #DAE0E9 !important;
}

.onoff-switch.off .btn:first-child {
    left: 0;
    margin: 2px 0 0 3px;
    text-indent: -9999px;
}

.onoff-switch.off .btn:last-child {
    left: 0px;
}

.onoff-switch.off span.telegram_status_on_ff {
    opacity: .3;
}


/**********google doc*******/

.g_doc_link {
    position: relative;
}

.bot_create_knowledgebase input {
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #474747;
}

.bot_create_knowledgebase input:focus {
    background:#fff;
    border: 1px solid #6859F5;
    outline: none;
    box-shadow: none;
    color: #474747;
}


.bot_create_knowledgebase label {
    position: relative;
    margin: 0px 0 8px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
}

.add_link_btn {
    background: #6859F5;
    border-radius: 12px;
    padding: 15px 30px 15px 30px;
    border: none;
    outline: none;
    float: right;
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    margin: 27px 0 0 0;
}

.add_domain_edit{
    float: left;
    width: 100%;
}

.add_domain_edit button.add_link_btn {
    background: #6859F5;
    border-radius: 12px;
    padding: 15px 30px 15px 30px;
    border: none;
    outline: none;
    float: right;
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: #FFF;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    margin: 27px 0 0 0;
    width: auto;
}

.add_link_btn i {
    font-size: 14px;
    margin: 0 0 0 8px;
}

.g_doc_link .mb-3 {
    width: calc(100% - 124px);
    margin: 0 0 14px 0 !important;
    display: inline-block;
}

.bot_create_knowledgebase .email_bg {
    position: absolute;
    top: 16px;
    left: 18px;
    font-size: 18px;
}


.bot_create_knowledgebase ul {
    background: #F2F6FC;
    border-radius: 12px;
    margin: 16px 0 0 0;
    overflow: hidden;
}

.bot_create_knowledgebase ul li {
    border-bottom: 1px solid #DAE0E9;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    padding: 12px;
    list-style-type: decimal;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.bot_create_knowledgebase ul li button {
    padding: 0px;
    background: none;
    border: none;
    color: #A1A8B9;
    font-size: 16px;
    float: right;
    background: #f2f6fc;
    margin: 2px 0px 0 0;
    min-width: 40px;
    min-height: 24px;
    z-index: 10;
    position: absolute;
    right: 0;
}

.bot_create_knowledgebase ul li button:hover {
    color: #6859F5;
}










.bot_create_knowledgebase ol {
    background: #F2F6FC;
    border-radius: 12px;
    margin: 0px 0 16px 0;
    overflow: hidden;
    float: left;
    width: 100%;
}

.bot_create_knowledgebase ol li {
    border-bottom: 1px solid #DAE0E9;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 

    padding: 12px;
    list-style-type: decimal;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}


.bot_create_knowledgebase ul li .file-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #121212;
}

.modal_bg_white .modal-content{
    background-color: #fff;
}

.modal_bg_white .modal-header h5{
    color: #121212;
    font-size: 26px;
    margin: 0 0 13px 0;
}

.modal_bg_white .modal-header p{
    color: #121212;
}

.bot_create_knowledgebase ol li button {
    padding: 0px;
    background: none;
    border: none;
    color: #78788E;
    font-size: 16px;
    float: right;
    background: #f2f6fc;
    margin: -4px 0px 0 0;
    min-width: 40px;
    min-height: 24px;
    z-index: 10;
    position: absolute;
    right: 0;
    width: auto;
}

.bot_create_knowledgebase ol li button:hover {
    color: #6859F5;
}


.doc_link_section {
    margin: 0 0 16px 0;
}

.invalid-feedback-file {
    font-size: 0.875em;
    color: #d92550;
    float: left;
}

.graph_height {
    margin: -33px 0 0 0;
}

.dropdown_static {
    position: static;
}

.dropdown_static .dropdown-toggle::after {
    display: none;
}

.bot_listing_table {
    border-radius: 10px;
}



.bot_listing_table table {
    margin: 0px;
    border: none;
    overflow: initial;
}

.bot_listing_table .table-responsive {
    border: none;
    border-radius: 0px;
    overflow: initial;
}

.bot_listing_table table thead {
    background-color: #fff;
}

.bot_listing_table table thead tr {
    background-color: #fff;
}

.widgetCodeModal .modal-content {
    background-color: #fff;
}

.widgetCodeModal .chat_widget_embedded {
    margin: 25px 0 30px 0 !important;
}

.widgetCodeModal .modal-header h5 {
    font-size: 28px;
    line-height: 30px;
    color: #121212;
}

.widgetCodeModal h4 {
    color: #121212;
}

.widgetCodeModal .modal-footer{
    margin-top: 0px !important;
}

.widgetCodeModal .login_btn{
    margin-top: 0px;
}

.add_client_modal .modal-content {
    background: #ffffff;
}

.add_client_modal .modal-content h3{
    color: #121212;
}

.add_client_modal .login_btn{
    color: #ffffff !important;
}

.add_client_modal .cancel_modal_button {
    color: #121212;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.add_client_modal input {
    border: 1px solid #c9d1d7;
    border-radius: 12px;
    padding-left: 52px;
    color: #78788e;
}

.dashboard_top_heading button.add_bot_btn{
    color: #fff !important;
    border-radius: 8px  !important;
    background: #6859F5 !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    margin-right: 0px !important;
}

.bot_listing_table table thead tr th {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #78788E;
    padding: 14px 10px;
}

.bot_listing_table table tbody tr td {
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1C1C1C;
    padding: 14px 10px;
    border-color: #C9D1D7;
}

.bot_listing_table table tbody tr td.table_bot_name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    min-width: 200px;
}

.bot_listing_table table tbody tr td.bot_info_social {
    min-width: 200px;
}

.bot_listing_table table tbody tr td.table_owner {
    min-width: 200px;
}



.bot_listing_table table tbody tr td.table_bot_name img {
    margin: 0 6px 0 0;
}

.bot_listing_table table tbody tr td.table_bot_name i {
    margin: 1px 6px 0 0;
    color: grey;
    font-size: 17px;
    float: left;
}

.datatable_icon{
    margin: 0px 6px 0 0;
    color: grey;
    font-size: 17px;
    float: left;
}

.bots_side_nav ul li i.fa-user-tie {
    float: left;
    font-size: 20px;
    margin: 1px 8px 0 0px;
    color: grey;
}

.bot_listing_table table tbody tr td.bot_info_social span {
    margin: 0 8px;
}


.bot_listing_table table tbody tr td.bot_info_social span i {
    font-size: 13px;
    margin: 0 7px 0 0;
}

.bot_listing_table table tbody tr td.bot_info_social span img {
    margin: -3px 7px 0 0;
    width: 16px;
}

.bot_listing_table table tbody tr td.table_bot_status {
    min-width: 180px;
}

.input_tr {
    width: 50px;
}

.th_actions {
    width: 50px;
    text-align: center;
}

.input_tr input[type="checkbox"] {
    width: 16px;
    height: 16px;
    background-color: #383c42;
    border-color: #5e6266;
    border-radius: 5px !important;
    margin: 2px 0 0 7px;
    float: left;
}

.table_bot_actions button {
    padding: 5px 8px 3px 8px;
    background: none;
    border: none;
    outline: none !important;
    margin: 4px 11px 0 0;
    color: #1C1C1C;
}

.table_bot_actions button:hover {
    background-color: rgba(247, 248, 248, 0.10);
}

.table_bot_actions button:hover i{
    color: #6859F5;
}

.table_bot_actions button:focus {
    background-color: rgba(247, 248, 248, 0.10);
}

.table_bot_actions button.active {
    background-color: rgba(247, 248, 248, 0.10);
}

.table_bot_actions button.show {
    background-color: rgba(247, 248, 248, 0.10) !important;
}

.table_bot_actions .dropdown-menu::before {
    display: none;
}

.table_bot_actions .dropdown-menu::after {
    display: none;
}

.table_bot_actions .dropdown-menu {
    border-radius: 8px;
    border: 1px solid rgba(247, 248, 248, 0.20);
    background: #6859f5;
    box-shadow: 0px 8px 36px 0px #9b9b9b;
    padding: 8px;
}

.table_bot_actions .dropdown-menu .dropdown-item img {
    margin: 0 8px 0 0;
}
.table_bot_actions .dropdown-menu .dropdown-item svg {
    margin: 0 8px 0 0;
}

.table_bot_actions .dropdown-menu .dropdown-item {
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 8px;
    color: #fff;
    margin: 0 0 5px 0;
}

button.dropdown-toggle.show i{
    color: #121212;
}

button.dropdown-toggle.show i:hover{
    color: #121212;
}

button.dropdown-toggle.show i:focus{
    color: #121212;
}


button.dropdown-toggle:focus{
    color: #121212;
}

.table_bot_actions .dropdown-menu .dropdown-item:hover {
    background: rgb(247 248 248 / 37%);
    border-radius: 8px;
}

.circle_status {
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: #fff;
    display: inline-block;
    margin: 0 7px 0 0;
}


.edit_account_form.bot_form_info input {
    border: none;
    background: none;
}

.edit_account_form.bot_form_info .mb-3 {
    margin: 0px !important;
}

.edit_account_form {
    padding: 0 0 0 40px;
}

.account_price_col {
    border-radius: 22px;
    background: #fff;
    display: flex;
    width: 100%;
    min-height: 512px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
    border: solid 1px #6859F5;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
}

.account_price_col h5 {
    color: var(--black, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    width: 100%;
    margin: 0 0 12px 0;
}

.account_price_col h4 {
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 41px;
    font-style: normal;
    font-weight: 500;
    line-height: 58px; 
    text-align: center;
    width: 100%;
    margin: 0 0 5px 0;
}

.account_price_col h4 span.price_sign {
    color: var(--primary-text, #6859F5);
    /* Chakra/h1 */
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
}

.account_price_col h4 span {
    color: var(--primary-text-secondary, #A1A8B9);
    font-family: Chakra Petch;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


.account_price_col p {
    color: var(--black, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    width: 100%;
    margin: 0 0 24px 0;
}


.plan_price_desc {}

.plan_price_desc p {
    color: rgba(18, 18, 18, 0.77);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 24px 0;
}

.plan_price_desc ul {}

.plan_price_desc ul li {
    color: var(--black, #121212);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin: 0 0 8px 0;
}


.edit_plan_button {
    border-radius: 4px;
    background: var(--primary-brand, #0098E9);
    display: flex;
    padding: 11px 12px;
    justify-content: center;
    align-items: center;
    color: var(--primary-text, #E9F2F7);
    text-align: right;
    /* Chakra/md semibold */
    font-family: Chakra Petch;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    width: calc(100% - 80px);
    position: absolute;
    bottom: 40px;
}

.edit_plan_button:hover {
    color: #fff;
}

.unsub_plan_button {
    border-radius: 4px;
    background: var(--primary-brand, #0098E9);
    display: flex;
    padding: 11px 12px;
    justify-content: center;
    align-items: center;
    color: var(--primary-text, #E9F2F7);
    text-align: right;
    /* Chakra/md semibold */
    font-family: Chakra Petch;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    width: calc(100% - 80px);
    position: absolute;
    bottom: 100px;
}

.unsub_plan_button:hover {
    color: #fff;
}

.edit_account_btn {
    border-radius: 4px;
    background: var(--primary-brand, #6859f5);
    display: flex;
    padding: 11px 12px;
    justify-content: center;
    align-items: center;
    color: var(--primary-text, #E9F2F7);
    text-align: right;
    /* Chakra/md semibold */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    min-width: 90px;
    margin: 15px 0 0 18px;
}


.edit_account_btn img {
    margin: 0 0 0 10px;
}


.account_section {
    margin: 10px 0 0 0;
    display: inline-block;
    width: 100%;
}



.padd_left_0 {
    padding-left: 0px !important;
}



.plans_page_heading {}

.plans_page_heading h3 {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; 
    margin: 0 0 24px 0;
    text-align: left;

}

.logo_plans {
    position: absolute;
    left: 22px;
    top: 34px;
}

.cross_plans {
    position: absolute;
    right: 22px;
    top: 34px;
}


.bread_crum {}

.bread_crum_inner {
    text-align: left;
    margin: 0 0 48px 0;
}

.bread_crum_inner ul {
    display: inline-block;
    padding: 4px 2px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
}

.bread_crum_inner ul li {
    float: left;
    list-style-type: none;
    padding: 8px;
    color: var(--primary-text-secondary, #A1A8B9);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.bread_crum_inner ul li:hover {
    background: rgba(104, 89, 245, 0.15);    
    box-shadow: 0px 2px 4px 0px rgba(105, 90, 181, 0.10);
    color: #6859F5;
}








.choose_plan_btn {
    
    border-radius: 12px;
    background: var(--main-blue, #6859F5);
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: -0.16px;
    padding: 15px 0px;
    border: none;
    width: calc(100% - 0px);
    margin: 0 0 36px 0;
}

.price_plan_1{
    background: var(--backgrounds-bg-2, #22272E);
    margin: 80px 0 20px 0;
}

.price_plan_2{
    background: var(--secondary-blue-10, rgba(43, 152, 212, 0.10));
}

.price_plan_3{
    margin: 80px 0 0 0;
    background: #081E2E;
}

.price_plan_2 .choose_plan_btn {
    border-radius: 4px;
border: 1px solid var(--secondary-blue-40, rgba(43, 152, 212, 0.40));
background: var(--secondary-blue-100, #45B9F6);
/* button main */
box-shadow: 0px 6px 16px 0px rgba(0, 152, 233, 0.40);
}


.plans_desctipn_bottom{
    margin: 60px 0 0 0;
}

.plans_desctipn_bottom h3{
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0 0 24px 0;
}

.plans_desctipn_bottom ul{
    float: left;
    width: 100%;
    margin: 0 0 35px 0;
    padding: 0 0 0 12px;
}

.plans_desctipn_bottom ul li{
    list-style-type: decimal;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 10px 0;

}

.plans_desctipn_bottom ul li strong{
    font-size: 16px;
}

.bot-test-widget #chatbot{ 

    padding: 0 0 20px 0px !important;
  }

  .bot-test-widget #msgWindow {
    margin-top: 0px;
    height: 560px;
    border: none;
    min-height: unset;
    max-height: unset;
}


.bot-test-widget #any-agent-msgs {
    margin: 0px 0px;
    min-height: 450px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    /* max-height: fit-content; */
    overflow: scroll;
    overflow-x: hidden;
    padding: 15px 16px;
    max-height: 450px;
}


.bot-test-widget .container-widget {
    margin-top: 0px;
    margin-right: 15px;
    position: fixed;
    right: 0;
    box-shadow: rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px;
    bottom: 30px;
    right: 1rem;
    max-width: 370px;
    max-height: 560px;
    z-index: 999999999;
}

.bot-test-widget .msg-window-container {
    border-radius: 0.75rem;
    overflow: hidden;
    height: 560px;
    border-radius: 10px;
}


.bot-test-widget .card-header-widget {
    padding: 16px 25px;
}

.drop_color_col {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 15px 0 0px 0;
    background: rgb(242 246 252);
    border: 1px solid #C3BDFB !important;
    padding: 15px 0;
    border-radius: 12px;
}

.drop_color_col_inner {
    float: left;
    max-width: 134px;
    margin: 1px;
    padding: 0;
    display: inline-block;
}

.drop_color_col_inner label {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin: 0 0 12px 0;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.drop_color_col_inner input[type="color"] {
    background: none !important;
    width: 30px;
    height: 30px;
    outline: none;
    display: inline-block;
    min-height: unset;
    margin: 0;
    padding: 0;
    border-radius: 100px !important;
    /* overflow: hidden; */
    border: solid 2px #fff;
    transition: transform 0.2s ease-in-out;
}

.drop_color_col_inner input[type="color"]:focus {
    border: transparent !important;
    transition: transform 0.2s ease-in-out;
}


.app-sidebar .scrollbar-container {
    height: calc(100vh - 100px);
}



.file_uploa_client{
    display: inline-block;
    width: 100%;
    text-align: center;
}

.f_u_container{
    display: inline-block;
    width: 100%;
}

.f_u_container span{
    border-radius: 16px;
border: 1px dashed var(--white-40, rgba(247, 248, 248, 0.40));
background: var(--white-10, rgba(247, 248, 248, 0.10));
width: 64px;
height: 64px;
display: inline-block;
overflow: hidden;
position: relative;
margin: 0 0 12px 0;
}

.f_u_container input {
    /* visibility: hidden; */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    text-indent: -999999px;
}

.f_u_container span img {
    position: absolute;
    top: 23px;
    left: 23px;
}

.f_u_container h6 {
    color: var(--primary-text, #E9F2F7);
    text-align: center;
    font-family: Chakra Petch;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin: 0 0 22px 0;
}


.cancel_modal_button {
    background: none;
    color: #E9F2F7;
    color: var(--primary-text, #E9F2F7);
    text-align: center;
    font-family: Chakra Petch;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    outline: none !important;
    border: none;
    width: 100%;
    margin: 19px 0 0 0;
}




.setting_buttons{
    background: none;
    color:  #121212;
    text-align: right;
    /* Chakra/md semibold */
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    float: right;
    margin: 14px 11px 0 0px;
}

.setting_buttons i{
    margin: 0 0 0 8px;
}


.setting_heading_bar a{
    float: right;
    margin: 0px 0 0 20px;
}


.client_setting_col{
    border-radius: 12px;
    border: 1px solid var(--white-10, rgba(247, 248, 248, 0.10));
    background-color:  #ffffff;
    box-shadow: 0 4px 29px 0 rgba(195,205,217,.25);
    padding: 24px 0;
}

.client_setting_col h4{
    color: var(--primary-text, #E9F2F7);
    font-family: Chakra Petch;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    margin: 0 0 22px 0;
    padding: 0 24px;
}

.client_setting_col h6{
    color: var(--primary-text, #E9F2F7);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 30px 0 12px 0;
    padding: 0 24px;
}



.edit_account_form.bot_form_info.client_setting_col input{
    background-color: #fff;
    border: 1px solid #c9d1d7;
    border-radius: 12px;
    color: #78788e;
    color: var(--mid-grey,#78788e);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    line-height: 18px;
    margin: 0 0 15px 0;
    padding-left: 50px;
}

.client_setting_col .buttons_footer {
    border-top: 1px solid #c9d1d7;
    padding: 24px 24px 2px 24px;
    margin: 0 0 0 0;
    display: inline-block;
    width: 100%;
}


.edit_account_form.bot_form_info.client_setting_col .mb-3 {
    max-width: 435px;
    margin: 0px 0 0 24px !important;
}


.buttons_footer {
    border-top: 1px solid var(--white-10, rgba(247, 248, 248, 0.10));
    padding: 24px 24px 2px 24px;
    margin: 24px 0 0 0;
    display: inline-block;
    width: 100%;
}


.back_button{
    background: #6859f5;
    float: left !important;
    margin: 0px;
    
}

.edit_account_btn.back_button img{
    margin: 0 10px 0 0;
}

.client_setting_col .edit_account_btn{
    margin:0px;
    width: 48%;
    float: right;
}
.bot_working{
    color: #6859F5;
}

.bot_working .circle_status{
    background-color: #6859F5;
}


.bot_pause{
    color: #ffc800;
}

.bot_pause .circle_status{
    background-color: #ffc800;
}


.bot_error{
    color: #FF476C;
}

.bot_error .circle_status{
    background-color: #FF476C;
}




.client_setting_col .email_bg {
    top: 45px !important;
}


a.logo-sr img {
    width: 38px;
    float: left;
}

.logo_text {
    color: var(--black, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 11px 0 0 7px;
    float: left;
}


::-webkit-scrollbar-thumb:hover {
    background: #78788E;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background: #78788E;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 4px;
    height: 7px;
  }
  
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #78788E;
    border: 0px none #ffffff;
    border-radius: 0px;
  }

  .dropdown_user_show {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 10;
}

.dropdown_user_show.open {
    display: block;
}


.header_user_info {
    float: right;
    width: auto;
}

.header_user_info p {
    float: left;
    width: auto;
    color: var(--mid-grey, #78788E);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-transform: uppercase;
    margin: 4px 12px 0 0;
    text-align: left;
}

.user_name_head {
    color: #121212;
    color: var(--black, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-transform: none;
    float: left;
    margin: 3px 0 0 0;
}

span.user_show_dropdown {
    float: right;
    cursor: pointer;
    color: grey;
    max-width: 50px;
    min-width: 50px;
    margin: 0 12px 0 0;
    font-size: 33px;
    border: solid 1px grey;
    border-radius: 100px;
    min-height: 50px;
    text-align: center;
    line-height: 43px;
}

.user_show_dropdown img{
    max-width: 44px;
}

.dropdown_user_show {
    float: left;
    min-width: 200px;
    background: #fff;
    border-radius: 0px 0 5px 5px;
    color: var(--black, #121212);
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding: 10px 11px 12px 11px;
    margin: 23px 0px 32px 0;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
}

.dropdown_user_show i {
    margin: 2px 13px 0 0;
    float: left;
}

.dropdown_user_show span.bot_name {
    float: left;
    width: 100%;
    padding: 10px 10px;
    border-radius: 6px;
}

.dropdown_user_show span.bot_name:hover{
    background-color: #6859F5;
    color: #fff;
    cursor: pointer;
}

button.schedule_demo {
    border: solid 1px #6859F5;
    border-radius: 8px;
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    padding: 14px;
    background: none;
    margin: 0 0 20px 0;
}

a.schedule_demo {
    border: solid 1px #6859F5;
    border-radius: 8px;
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    padding: 14px;
    background: none;
    margin: 0 0 20px 0;
    float: left;
}

.four-step-section{
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 24px;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
}

.four-step-section h4{
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0 0 16px 0;
}

.four-step-section h6{
    color: var(--mid-grey, #78788E);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin: 0 0 24px 0;
}

.four-step-section p{
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin: 0 0 8px 0; 
}

.four-step-section p span{
    float: right;
}

.four-step-line-progress {
    float: left;
    width: 100%;
    height: 12px;
    overflow: hidden;
    background-color: #DAE0E9;
    border-radius: 24px;
}

.accordian_next_bttton {
    border-radius: 12px;
    background: #6859F5;
    background: var(--main-blue, #6859F5);
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: #FFF;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    border: none;
    width: 100%;
    padding: 15px 10px;
    margin: 10px 0 0 0;
}


.numbring {
    background-color: #F2F6FC;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    color: #6859F5;
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0px 16px 0 0;
    text-align: center;
    line-height: 42px;
}

.accordian_Section_bot .accordion-button p {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0px;
}

.accordian_Section_bot .accordion-button p  span{
    color: var(--mid-grey, #78788E);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}












/* Line Progress Bar */
.line-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20px;
    background-color: #ccc;
  }
  
  .step {
    width: 25%;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f1f1f1;
    height: 12px;
    float: left;
    text-indent: -9999px;
}
  
  .step.active {
    background-color: #6859F5;
    color: white;
  }
  
  /* Circle Progress Bar */
  
  .label_text_count{
    float: right;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

  .label_text_count .smaller{
    color: var(--mid-grey, #78788E);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

  .set-size {
    font-size: 30px;
    float: right;
    margin: -7px 0 0 8px;
}

  .pie-wrapper {
    height: 1em;
    width: 1em;
    float: left;
    margin: 0px 0 0 0;
    position: relative;
  }
  .pie-wrapper:nth-child(3n+1) {
    clear: both;
  }
  .pie-wrapper .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0;
  }
  .pie-wrapper .pie .half-circle {
    height: 100%;
    width: 100%;
    border: 0.1em solid #6859F5;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
  }
  .pie-wrapper .label {
    background: #34495e;
    border-radius: 50%;
    bottom: 0.4em;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: 0.25em;
    left: 0.4em;
    line-height: 2.8em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.4em;
  }
  .pie-wrapper .label .smaller {
    color: #bdc3c7;
    font-size: 0.45em;
    padding-bottom: 20px;
    vertical-align: super;
  }
  .pie-wrapper .shadow {
        height: 100%;
        width: 100%;
        border: 3px solid #bdc3c7;
        border-radius: 50%;
        box-shadow: none !important;
    }
 
  .pie-wrapper.progress-25 .pie .half-circle {
    border-color: #6859F5;
  }
  .pie-wrapper.progress-25 .pie .left-side {
    transform: rotate(100deg);
  }
  .pie-wrapper.progress-25 .pie .right-side {
    display: none;
  }
  .pie-wrapper.progress-50 .pie {
    clip: rect(auto, auto, auto, auto);
  }
  .pie-wrapper.progress-50 .pie .half-circle {
    border-color: #6859F5;
  }
  .pie-wrapper.progress-50 .pie .left-side {
    transform: rotate(186deg);
  }
  .pie-wrapper.progress-50 .pie .right-side {
    transform: rotate(180deg);
  }
  .pie-wrapper.progress-75 .pie {
    clip: rect(auto, auto, auto, auto);
  }
  .pie-wrapper.progress-75 .pie .half-circle {
    border-color: #6859F5;
  }
  .pie-wrapper.progress-75 .pie .left-side {
    transform: rotate(270deg);
  }
  .pie-wrapper.progress-75 .pie .right-side {
    transform: rotate(180deg);
  }

  .pie-wrapper.progress-100 .pie {
    clip: rect(auto, auto, auto, auto);
  }
  .pie-wrapper.progress-100 .pie .half-circle {
    border-color: #6859F5;
  }
  .pie-wrapper.progress-100 .pie .left-side {
    transform: rotate(360deg);
  }
  .pie-wrapper.progress-100 .pie .right-side {
    transform: rotate(180deg);
  }
  

  

.bot_page_top_rightbar{
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    padding: 24px;
    max-width: 450px;
    float: right;
    margin: 69px 0 0 0;
    position: sticky;
    top: 125px;
}

.bot_page_top_rightbar header{
    float: left;
    margin: 0 0 15px 0px;
    padding: 0;
    width: 100%;
}

.bot_page_top_rightbar h4 {
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    margin: 0px;
    float: left;
}

.bot_form_live_data{
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
}

.bot_form_live_data ul{
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
}

.bot_form_live_data ul li{
    float: left;
    margin: 0 0 12px 0;
    padding: 0 0 4px 0;
    width: 100%;
    list-style-type: none;
    border-bottom: 1px solid var(--light-grey, #DAE0E9);
}

.bot_form_live_data ul li h5{
    float: left;
}

.bot_form_live_data ul li p{
    float: right;
    margin: 0;
}

.bot_form_live_data ul li span{
    margin: 0 0 0 8px;
}

.bot_form_live_data ul li span img{
    margin: 0 3px 0 0;
}

.toggle_outer_set {
    float: left;
    width: 100%;
    margin: 0 0 15px 0;
}

.bot_setup_section .form-control:disabled, .form-control[readonly]{
    background: #fff;
    /* border: 1px solid #C9D1D7; */
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #78788E;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d92550'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d92550' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.dashboard_bot_list{
    float: left;
    margin: 0px;
    padding: 0 17px;
    width: 100%;
}

.dashboard_bot_list_inner{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    padding: 24px;
}


.dashboard_bot_list_inner .card-body{
    padding: 0px;
}

.dashboard_bot_list_inner .add_bot_btn {
    border-radius: 8px;
    background: #6859F5;
    color: var(--white-100, #FFF);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-right: 0px;
}







.email_to_notify_section .form-control {
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #78788E;
}


.email_to_notify_section label {
    position: relative;
    margin: 0px 0 8px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #474747;
}


.email_to_notify_section {
    margin: 0 0 40px 0;
}


.goal_section input {
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #474747;
}

.goal_section input:focus {
    background:#fff;
    border: 1px solid #6859F5;
    outline: none;
    box-shadow: none;
    color: #474747;
}

.button_save_draft {
    border-radius: 10px;
    border: 1px solid #6859F5;
    border: 1px solid var(--main-blue, #6859F5);
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: #6859F5;
    color: var(--main-blue, #6859F5);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    width: 100%;
    background: none;
    padding: 14px 0;
    margin: 15px 0 0 0;
}

.button_save_draft:hover{
    background-color: #6859F5;
    color: #fff;
}

.padd_0 {
    padding: 0px !important;
}



.plans_page_section{
    float: left;
    width: 100%;
    padding: 28px 0 0 0px;
}

.plans_user_name{
    float: left;
    width: auto;
    margin: 0 0 24px 0;
}



.plans_user_name h5 {
    margin: 23px 0 10px 0;
    float: left;
    color: var(--black, #121212);
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    min-width: calc(100% - 130px);
}

.plans_user_name img {
   float: left;
   max-width: 68px;
   min-width: 68px;
   margin: 0 24px 0 0;
}

.plans_user_name .fa-user-tie {
    color: grey;
    float: left;
    max-width: 68px;
    min-width: 68px;
    margin: 0 12px 0 0;
    font-size: 50px;
    border: solid 1px grey;
    border-radius: 100px;
    min-height: 68px;
    text-align: center;
    line-height: 63px;
}

.user_show_dropdown .fa-user-tie {
    max-width: 44px;
}

.plans_user_name h6 {
    color: var(--mid-grey, #78788E);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; 
    margin: 0px 0 0 0;
    float: left;
    max-width: 144px;
    color: #8F8FA7;
    text-align: left;
    min-width: calc(100% - 130px);
    letter-spacing: -0.01em;
    
}

.profile_cols_section{
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.profile_personal_info{
    float: left;
    margin: 0px 0 12px 0;
    padding: 28px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    border-radius: 16px;
    position: relative;
}

.profile_personal_info h4 {
    float: left;
    width: 100%;
    margin: 15px 0 20px 0;
    padding: 0px;
    color: var(--black, #121212);
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}


.profile_personal_info label{
    color: var(--dark-grey, #474747);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 6px 0;
}

.profile_personal_info input{
    float: left;
    margin: 0 0 12px 0;
    padding: 0 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: 1px solid var(--stroke, #C9D1D7);
    background: var(--white, #FFF);
}


.profile_save_button{
    float: right;
    margin: 0px 0 12px 0;
    padding: 0;
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    background: var(--gradient, linear-gradient(90deg, #53A2FF 41.98%, #82F0FF 100%));
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}

.profile_save_button:hover{
    background: linear-gradient(89deg, #8295FF -14.9%, #54A9B4 117.91%);
}


.profile_btn_changes {
    float: left;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    border-radius: 12px;
    background: var(--main-blue, #6859F5);
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    padding: 15.5px 40px;
    border: none;
    margin: 0 0 30px 0;
}

.profile_personal_info header{
    float: left;
    width: 100%;
}

.header_edit_plan{
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
}

.header_edit_plan h3{
    float: left;
    margin: 0;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; 
}

.edit_plan_btn {
    padding: 10px 13px;
    border-radius: 8px;
    border: 1px solid #6859F5;
    border: 1px solid var(--main-blue, #6859F5);
    background: var(--white, #FFF);
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    float: right;
}

.edit_plan_btn i {
    font-size: 16px;
    color: #6859F5;
    margin: 2px 0 0 8px;
    float: right;
}


.profile_personal_info p {
    float: left;
    width: 100%;
    margin: 0px 0 20px 0;
    padding: 0px;
    color: var(--mid-grey, #78788E);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}

.outreach_section_main {
    float: left;
    margin: 12px 0 0 0;
    padding: 0;
}

.outreach_section_main h3{
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    float: left;
    margin: 6px 0 25px 0;
}

.button_d_temp{
    border-radius: 8px;
    border: 1px solid var(--main-blue, #6859F5);
    padding: 14px;
    color: var(--main-blue, #6859F5);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    background: none;
    float: right;
    margin: 0 0 25px 10px;
}


.create_campaign_section{
    float: left;
    margin: 0px;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 29px 0px rgba(195, 205, 217, 0.25);
    padding: 24px;
    margin: 24px 0 0 0;
    width: 100%;
}

.create_campaign_section .card-body{
    padding: 0px;
}

.create_campaign_section label{
    color: var(--dark-grey, #474747);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 7px 0px;
}

.create_campaign_section input{
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    min-height: 50px;
    margin: 0 0 0 0;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
}


.create_campaign_section textarea {
    min-height: 160px;
    background: #fff;
    border: 1px solid #C9D1D7;
    border-radius: 12px;
    margin: 0 0 0 0;
    color: var(--black, #121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding: 16px 12px;
}


.start_comain_btn{
    border-radius: 12px;
    background: var(--main-blue, #6859F5);
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    width: 100%;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 118.75% */
    letter-spacing: -0.16px;
    border: none;
    padding: 15px;
}


.chat_edit_wodget{
    margin-top: 0px;
}

.chat_edit_wodget .chat_widget_embedded{
    margin: 0 !important;
}

.for_edit_bot_set{
    margin-top: 38px;
}

.initial_message_bot h6{
    color: var(--black,#121212);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 10px;
}

.drop_color_col {
    margin: 0 0 13px 0;
    float: left;
    width: 100%;
}

button.email-tags-cross {
    width: auto !important;
    padding: 0px 2px;
    margin: 0px 0 0 3px;
    font-size: 12px;
    height: 16px;
    line-height: 10px;
}

.resend_page_logo img{
    max-width: 90px;
}

.resend_page_logo {
    margin: 40px 40px 60px 40px;
}

.custom_login_resend h4 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        color: #121212;
}

.custom_login_resend .login_btn {
    font-weight: 500;
    font-size: 17px;
    border-color: #6859F5;
}

.custom_login_resend .creat_account{
    color: #121212;
    font-weight: 500;
}

.datatale_cus {
    margin: 0 0 0 0;
}

.rdt_TableHeadRow{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.rdt_TableRow{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #474747;
}

.rdt_Pagination{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #474747
}

.form-control[readonly]{
    background-image:none !important;
}

.bot_setup_section button.add_link_btn{
    width: auto !important;
    background: #6859F5;
    border-radius: 12px;
    padding: 15px 30px 15px 30px;
    border: none;
    outline: none;
    float: right;
    box-shadow: 2px 2px 15px 0px rgba(153, 153, 169, 0.15);
    color: #FFF;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.16px;
    margin: 27px 0 0 0;
}

.bot_form_live_data ul li i.fa-user-tie {
    float: right;
    font-size: 20px;
    margin: 1px 8px 0 0px;
    color: grey;
}

@media (max-width: 768px){
    .plans_user_name h4 {
        font-size: 20px;
    }

    .plans_col_card{
        min-height: unset;
    }

}

























